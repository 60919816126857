import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkAuthorization } from '../helpers/helpers';

const AuthRoute = ({
  component: Component,
  redirect: pathname,
  ...rest
}) => {
  const Routes = (props) => {
    if (rest.path == '/404') {
      return (<Component {...rest} {...props} />)
    }
    else if ((rest.accountAccess == 'Not Allowed' && rest.path == '/access-denied')) {
      return (<Component {...rest} {...props} />)
    }
    else if (rest.authorized === false) {
      return (
        <Route
          {...rest}
          render={props =>

            <Component {...rest} {...props} />

          }
        />
      );
    } else {
      return (

        <Redirect
          to={{
            pathname: '/leads',
            state: { from: props.location },

          }}
        />
      );
    }
  }
  return (
    <Routes />
  );
};

AuthRoute.defaultProps = { redirect: '/' };

// AuthRoute.propTypes = {
//   component: PropTypes.object.isRequired,
//   redirect: PropTypes.string,
// };

export default AuthRoute;